---
title: 'Configuring your setup'
metaTitle: 'Admin-UI Docs | Getting started > Configuring your machine'
metaDescription: 'Explains what is needed for your machine to run Admin-UI project locally'
---

This documentation will guide you on making sure you have the right setup for your machine to run Admin-UI locally and be able to contribute to the repo.

## 1 - Configuring your Github settings

**Access to the right workgroup**

Make sure you have been added to the [Coveo organization](https://github.com/coveo) on GitHub, you will need to ask the IT department for that (submit a request on the [IT Help Desk portal](https://www.coveogo.com/sp/) or look for [the #it channel on slack](https://coveo.slack.com/archives/C054UNHKW)).

If you are a new core admin-ui developer, you will need to be added as a member of both the [developers team](https://github.com/orgs/coveo/teams/developers) and the [AdminUI team](https://github.com/orgs/coveo/teams/admin-console).

### Adding your SSH key

The github documentation is really well done and will help you [set up your SSH key configuration](https://docs.github.com/en/authentication/connecting-to-github-with-ssh/adding-a-new-ssh-key-to-your-github-account) or even [generate one if you don't have one yet](https://docs.github.com/en/authentication/connecting-to-github-with-ssh/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent).

### Adding your GPG to sign your commits

The github documentation is really well done and will help you [set up your GPG key configuration](https://docs.github.com/en/authentication/managing-commit-signature-verification/adding-a-new-gpg-key-to-your-github-account) or even [generate one if you don't have one yet](https://docs.github.com/en/authentication/managing-commit-signature-verification/generating-a-new-gpg-key).

## 2 - Install [Git](https://git-scm.com/)

Please use the commands below for your corresponding operating system:

### Git on Linux

```bash
sudo apt install git
```

### Git on OSX

```bash
brew install git
```

### Git on Windows

1. Go to https://git-scm.com/download/win
2. Download the installer and execute it by following the wizard

## 3 - Configure Git

```bash
git config --global user.name "your fullname"
git config --global user.email my@email.com
```

The use of a `.gitignore` file at the global level is part of good development practice.
You will avoid adding rules to ignore that only match your development environment in the `.gitignore` files of projects versioned with Git.

### Git on Linux & OSX

```bash
# configure your gitignore file for your global configuration (this file is used for to ignore all files of your development platform)
git config --global core.excludesfile "$HOME/.gitignore_global"
```

### Git on Windows

```bash
# configure your gitignore file for your global configuration with PowerShell (this file is used for to ignore all files of your development platform)
git config --global core.excludesfile "$HOME\.gitignore_global"
```

<br />

<details>
    <summary>Here is an example of a `.gitignore_global` file for the overall configuration of your development platform that you must change according to your needs</summary>

```
###################
# Compiled source #
###################
*.com
*.class
*.dll
*.exe
*.o
*.so

############
# Packages #
############
# it's better to unpack these files and commit the raw source
# git has its own built in compression methods
*.7z
*.dmg
*.gz
*.iso
*.jar
*.rar
*.tar
*.zip

######################
# Logs and databases #
######################
*.log
*.sql
*.sqlite
get_dump.sh

######################
# OS generated files #
######################
.DS_Store
.DS_Store?
._*
.Spotlight-V100
.Trashes
ehthumbs.db
Thumbs.db
*~
.*.swp

#######
# IDE #
#######
nbproject
.idea
.project
.buildpath
.project
.code-workspace
.vscode
.setting/org.eclipse.php.core.prefs
.settings/org.eclipse.wst.common.project.facet.core.xml

##########
# DOCKER #
##########
docker-compose.override.yml
docker-compose.override.yaml

#########
# Other #
#########
issue.md
todo.md
```

</details>

## 4 - Install [nvm](https://github.com/nvm-sh/nvm)

Node Version Manager (or NVM) makes it easier to install and update Node.js, we strongly advise to manage Node.js version this way instead of installing it manually.

Please uninstall any existing versions of NVM, Node.js and NPM before installing nvm.

### nvm on Linux

The command which follows is currently used for the installation but [we advise you to visit the creator's repository for more complete documentation](https://github.com/nvm-sh/nvm#installing-and-updating)

```bash
# go to https://github.com/nvm-sh/nvm/releases to get the latest version number (in my case it was 0.39.1)
curl -o- https://raw.githubusercontent.com/nvm-sh/nvm/v0.39.1/install.sh | bash
```

### nvm on OSX

For the installation of nvm on OSX we offer 2 choices:

1. [installation as on Linux with the installer from the creator's repository](https://nodesource.com/blog/installing-node-js-tutorial-using-nvm-on-mac-os-x-and-ubuntu/)
2. [installation via homebrew which should be easier](https://tecadmin.net/install-nvm-macos-with-homebrew/)

### nvm on Windows

For the installation of nvm on Windows you can follow [the installation described on the nvm-windows project repository](https://github.com/coreybutler/nvm-windows#installation--upgrades).

## 5 - Install [Node.js](https://nodejs.org/en/) with [nvm](https://github.com/nvm-sh/nvm)

Open a terminal (use PowerShell or cmd with Windows)

```bash
# install the current LTS version of Node.js
nvm install --lts --default
nvm install-latest-npm
# verify the installation
node --version
npm --version
```

## 6 - Install [pnpm](https://pnpm.io/installation)

Open a terminal (use PowerShell or cmd with Windows)

```bash
# install the latest version of pnpm with npm
npm install --global pnpm
# or install the latest version of pnpm with npx
npx pnpm add --global pnpm
# verify the installation
pnpm --version
# make sure it's set up to use the package-lock file
pnpm config set package-lock true
```

To change your pnpm version, run `pnpm update --global pnpm` in your terminal.

## 7 - Update the host file (optional)

The `localhost.corp.coveo.com` URL is required to share your local demos with your peers on the coveo local network.
The `local.cloud.coveo.com` URL is required to use our applications in HTTPS locally.

### on Linux & OSX

Edit `/etc/hosts` with admin rights and add this line.

```bash
127.0.0.1 localhost.corp.coveo.com
127.0.0.1 local.cloud.coveo.com
```

### on Windows

Edit `C:\Windows\System32\drivers\etc\hosts` with admin rights and add this line.

```bash
127.0.0.1 localhost.corp.coveo.com
127.0.0.1 local.cloud.coveo.com
```

## 8 - Installing an SSL certificate (optional)

To create a development SSL certificate, we'll use the [mkcert](https://github.com/FiloSottile/mkcert) project.
This cross-platform project is easy to use. [Please follow the installation instructions for your operating system.](https://github.com/FiloSottile/mkcert?tab=readme-ov-file#installation)

Open a terminal (use PowerShell or cmd with Windows)

```bash
# create your `.cert` folder at the root of the project
mkdir .cert
# create your certificate and key in the same file
mkcert -key-file .cert/_cert.pem -cert-file .cert/_cert.pem 'local.cloud.coveo.com'
```

Enable the use of SSL in our applications via an environment variable.

```bash
# for @apps/platform
echo 'USE_SSL=true' > ./apps/platform/.env.local
# for @apps/commerce-hub
echo 'USE_SSL=true' > ./apps/commerce-hub/.env.local
# for @apps/knowledge-hub
echo 'USE_SSL=true' > ./apps/knowledge-hub/.env.local
```

Applications are automatically launched on urls:

-   [https://local.cloud.coveo.com:9000](https://local.cloud.coveo.com:9000) for Platform
-   [https://local.cloud.coveo.com:9001](https://local.cloud.coveo.com:9001) for CommerceHub
-   [https://local.cloud.coveo.com:9002](https://local.cloud.coveo.com:9002) for KnowledgeHub

## 9 - Mandatory VSCode extensions

If you are a VSCode user, you need to install two mandatory extensions: ESLint and Prettier. Detailed information is available on the [Appendix 1 - VSCode configuration](/getting-started/appendix-vscode-configuration) page.

You can also find a [list of suggested extensions](/getting-started/appendix-vscode-extentions-suggestion). They are not mandatory but can be helpful tools to improve your productivity or simply make your coding experience better.

---

## Our repositories

[These are all the repositories we commonly work with](/getting-started/appendix-running-local-changes#other-projects-maintained-by-our-team). You can clone them all using your preferred method (HTTPS or SSH **(recommended)**).

## (Optional) Useful browser plugins

Here are some examples of plugins for the Chrome browser:

1. [React Dev Tools](https://chrome.google.com/webstore/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi) - Invaluable when debugging React components in the browser
2. [Redux Dev Tools](https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd) - Shows various state trees and actions
3. [Pesticide](https://chrome.google.com/webstore/detail/pesticide-for-chrome-with/neonnmencpneifkhlmhmfhfiklgjmloi) - Overlays every element with a border for CSS debugging
