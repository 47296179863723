import {FunctionComponent} from 'react';
import {Attributes} from '*.mdx';

const removeLeadingNumber = (id: string | null) => id?.replace(/^\d+-/, '');

export const getMdComponent = async (section: string, id: string | null): Promise<MdMetadata> => {
    const idWithoutLeadingNumber = removeLeadingNumber(id);
    return getAllMdIds(section).find((md) => md.id === idWithoutLeadingNumber);
};

export type MdMetadata = {
    id: string;
    section: string;
    path: string;
    Component: FunctionComponent;
} & Attributes;

export const getAllMdIds = (section: string | null): MdMetadata[] => {
    const modules = import.meta.glob('./content/**/*.mdx', {eager: true}) as Record<
        string,
        {frontmatter: Attributes; default: FunctionComponent}
    >;
    return Object.entries(modules)
        .filter(([path]) => (section ? path.includes(`/${section}/`) : true))
        .map(([path, {frontmatter, default: Component}]) => {
            const pathSegments = path.split('/');
            const isSection = pathSegments.length === 4;
            return {
                path,
                id: removeLeadingNumber(pathSegments[pathSegments.length - 1].replace('.mdx', '')),
                section: isSection ? pathSegments[2] : null,
                Component,
                ...frontmatter,
            };
        });
};

export type Tree = {
    [key: string]: Tree | MdMetadata;
};

export const isContent = (node: Tree | MdMetadata): node is MdMetadata => 'path' in node;

/**
 * Builds a tree structure from retrieved markdown files
 */
export const buildContentTree = (paths: MdMetadata[]): Tree => {
    const root: Tree = {};

    const addPathToTree = (node: Tree, segments: string[], pathObj: MdMetadata) => {
        if (segments.length === 0) {
            return;
        }

        const [currentSegment, ...remainingSegments] = segments;
        const key = currentSegment.replace('.mdx', '');

        if (remainingSegments.length === 0) {
            if (key === 'index') {
                node['/'] = pathObj;
            } else {
                node[key] = pathObj;
            }
        } else {
            if (!node[key]) {
                node[key] = {};
            }
            addPathToTree((node[key] as Tree) ?? {}, remainingSegments, pathObj);
        }
    };

    paths.forEach((pathObj) => {
        const segments = pathObj.path.split('/').slice(2); // Remove './content'
        addPathToTree(root, segments, pathObj);
    });

    return root;
};
