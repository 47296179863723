---
title: 'Administration Console tips'
metaTitle: 'Admin-UI Docs | Guides > Administration Console tips'
---

This guide will help make your life easier when testing, developing or using the Administration Console.

## Using a test user

When logging in to the Administration Console with the Google provider, your Coveo user will have power admin privileges not available to standard users. This is because of the Global Group feature used only internally.

To reproduce the behavior of an actual user, you can invite your Coveo email to a group in your organization using the Microsoft provider. You can then log in with your Coveo email using the Microsoft provider instead of Google.

![compliance checkbox](./images/admin-console-tips-invite.png)

## Modifying the UI feature flags of a user

If you want to try [UI Feature Flag](/guides/ui-feature-flags) with a test user, you might not have access to the internal Feature Flags panel. You can still modify the UI feature flags of the logged user using the browser console. All you need is the flag ID : you can find it either in the code or by viewing a request payload from your browser using a Coveo admin user.

![flag ID](./images/admin-console-tips-flag-id.png)

Once you have your ID, open your browser console and user the following commands to add, remove or reset all UI feature flags for the active user:

-   `admin.user.addFeatureFlag('flag-id')`
-   `admin.user.removeFeatureFlag('flag-id')`
-   `admin.user.resetFlags()`

## Testing the What's New notification

To trigger What's New notifications, you can use the following command in your browser console :
`admin.user.sawWhatsNew(null)`

![flag ID](./images/admin-console-tips-whats-new.png)

## Testing the Token expiration prompt

When logged in the platform, the user token expires after 4 hours in production, or 24 hours in dev/staging. This means the user is logged out when expired and changes are lost. A prompt is now shown 15 minutes before the token expires. Click the log in again button to open a modal which grants a new access token without refreshing the current page, so unsaved changes are not lost.

You can manually trigger the prompt by executing the following command in your browser console :
` const event = new Event('helper:showTokenRenewalPrompt', {bubbles: true}); document.dispatchEvent(event);`

![flag ID](./images/admin-console-tips-token-expiration.png)

## Using different UI and backend environment

It's possible to use the Administration Console with different version for the UI and the platform backend. You can specify which backend version you want to use by adding `?dev` or `?stg` to the url.
To use the development UI on a staging backend, you would use this: `https://platformdev.cloud.coveo.com/admin/?stg`.

This can also be used in demo link when testing a pull request. The URL would be `https://platformdev.cloud.coveo.com/admin/feature/branch-name/index.html?stg`. This can be useful to test a new feature in a pull request with a setup available in staging.

> Note that while browsing the Administration Console under this setup, switching organization with the organization picker will redirect you to the normal URL. You can either directly change the organization ID in the URL, or adapt the URL after the redirection.

You can also point to a local backend. For example if you have the platform service running locally on your machine: `https://platformdev.cloud.coveo.com/admin/?platform=http://localhost:8080`
