---
title: 'Appendix 3 - Linking our other projects'
metaTitle: 'Admin-UI Docs | Getting started > Appendix 3 - Linking our other projects'
metaDescription: 'How to link other projects locally with Admin-UI'
---

## Other projects maintained by our team

**Dependencies**

-   [Plasma](https://github.com/coveo/plasma) by [the UI Tooling team](https://github.com/orgs/coveo/teams/ui-tooling/members)
-   [Platform Client](https://github.com/coveo/platform-client) by [the DX team](https://github.com/orgs/coveo/teams/dx/members)

**CI & CD**

-   [JSAdmin Infra](https://github.com/coveo/jsadmin-infra) by [the UI Advisors team](https://github.com/orgs/coveo/teams/admin-console/members)
-   [JSAdmin Pipeline](https://github.com/coveo/jsadmin_pipeline) by [the UI Advisors team](https://github.com/orgs/coveo/teams/admin-console/members)

**Quality**

-   [Cloud Admin Tests](https://github.com/coveo/cloud-admin-tests) by [the UI Advisors team](https://github.com/orgs/coveo/teams/admin-console/members) & by Gabriel [![Gabriel Provost](https://github.com/gprovostqa.png?size=15)](https://github.com/gprovostqa)

## Running Admin-UI with other packages that we maintain

**Why do you want to do this?**

We can run other packages with Admin-UI to help debug or test changes locally.

If you did some changes in the [`platform-client`](https://github.com/coveo/platform-client) package or the [`plasma`](https://github.com/coveo/plasma) package, you can test them directly in the Admin-UI before having to publish these changes on npm.

Make sure you have your Admin-UI setup locally following this [guide](/getting-started/configuring-your-machine).

## Local changes from plasma

1. Make sure your Admin-UI local server is not running.
2. Link the _plasma_ package to the _admin-ui_ with `pnpm run link:plasma`. We assume you have a folder named `plasma` as a sibling of `Admin-UI` folder.
3. Build the _plasma_ package according to its [documentation](https://github.com/coveo/plasma#setup).
4. Uncomment the `optimizeDeps.exclude` option in the `vite.config.ts` file of the Admin-UI (this is to exclude the packages from the pre-bundling phase to make sure your local changes are surfaced in the app.)
5. [Launch the Admin-UI local setup](#launch-of-the-project-in-development-with-hot-reloading) - using `pnpm start` and going through the prompts.

> Note: You can link the `plasma` to a specific package of your choice with `pnpm run link:package plasma <package/path>`

## Local changes from platform-client

1. Make sure your Admin-UI local server is not running.
2. Link the _platform-client_ package to the _admin-ui_ with `pnpm run link:platform-client`. We assume you have a folder named `platform-client` as a sibling of `admin-ui` folder.
3. Build the _platform-client_ package according to its [documentation](https://github.com/coveo/platform-client#build).
4. Uncomment the `optimizeDeps.exclude` option in the `vite.config.ts` file of the Admin-UI (this is to exclude the packages from the pre-bundling phase to make sure your local changes are surfaced in the app.)
5. [Launch the Admin-UI local setup](#launch-of-the-project-in-development-with-hot-reloading).

> Note: You can link the `platform-client` to a specific package of your choice with `pnpm run link:package platform-client <package/path>`
