import {createFileRoute} from '@tanstack/react-router';
import {MdPage} from '../../components/MdPage';
import {getMdComponent} from '../../getMdComponent';

const SectionComponent = () => {
    const Section = Route.useLoaderData();
    return <MdPage {...Section} />;
};

export const Route = createFileRoute('/_auth/$section/')({
    loader: async ({params: {section}}) => getMdComponent(section, 'index'),
    component: SectionComponent,
});
