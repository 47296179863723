---
title: 'Appendix 1 - VSCode configuration'
metaTitle: 'Admin-UI Docs | Getting started > Appendix 1 - VSCode configuration'
metaDescription: 'VSCode configurations to help you become more productive!'
---

Your local Vscode settings live in the `./.vscode/settings.json` file. This document should not be committed, being tailored for your local environment.

## ESLint settings

1. Install the [`ESLint` extension](https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint) maintained by Microsoft.
2. in `./.vscode/settings.json`, add some eslint configs.

```
{
    [...]
    "eslint.validate": ["javascript", "javascriptreact", "typescript", "typescriptreact"],
        "eslint.workingDirectories": [
            "./packages/jsadmin-search-optimization",
            "./packages/jsadmin-machine-learning",
            "./packages/jsadmin-usage-analytics",
            "./packages/jsadmin-activity-browser",
            "./packages/jsadmin-commerce",
            "./packages/jsadmin-common",
            "./packages/jsadmin-log-browser",
            "./packages/jsadmin-oauth-popup",
            "./packages/jsadmin-service",
            "./packages/v2",
            "./locales",
            "./qpl-converters"
        ]
}
```

## Prettier settings

1. Install the [`Prettier` extension](https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode) maintained by Prettier.

## Suggested save actions

1. in `./.vscode/settings.json`, add:

```
{
    [...]
    "editor.codeActionsOnSave": [
        "source.organizeImports",
        "source.fixAll.eslint"
    ],
    "editor.formatOnSave": true
}
```
