---
title: 'Localizing strings'
metaTitle: 'Admin-UI Docs | Best practices > Localizing strings'
metaDescription: 'How we localize any visible text in Admin-UI'
---

## What is string localization?

Strings localization or internationalization is the process of making every text visible by the user easily translatable.

### Why

-   Even though the Admin Console UI is currently only available in English, it might not always be the case in the future.
-   It makes it easier for the documentation team to review and make changes to the visible text of the Admin Console UI, because all the strings are in the same place.

### How

Suppose you want to localize a string inside the `empty-states` package. You would have to add your string in one of the `.json` files inside the `src/strings` folder (e.g., `components/empty-states/src/strings/strings.json`).

```json
{
    "yourString": "This is the text that you need to show"
}
```

Then you can output that string in the code where you need it using the `Locales.format` function.

```tsx
import {Locales} from '../strings/Locales'; // located in src/strings/Locales.ts

const yourString = Locales.format('yourString');

// You can also use the function directly in a React component:
export const MyComponent: FunctionComponent = () => <div>{Locales.format('yourString')}</div>;
```

## Strings sub-folder nesting

When `.json` string files become too large, we can split and restructure them using sub-folders. We have an example of this in the `jsadmin-search-optimization` folder.

For example if you want to display a string from the `packages/jsadmin-search-optimization/src/strings/Triggers/triggers.json` file you would do it like so:

```ts
locales.format('Triggers.addTrigger'); // outputs "Add rule"
```

## Formatting to title case (deprecated)

_This option is deprecated, our documentation team prefers us to use the correct casing in the string_

Any localized string can be formatted to be in title case with the `titleCase` option.

```json
{
    "pizzaTaste": "pizza tastes good"
}
```

```ts
Locales.format('pizzaTaste', {titleCase: true});
// outputs "Pizza Tastes Good"
```

## Parameterized strings

Localized strings can also be parameterized. It helps to avoid declaring multiple instances of the same string with small differences.

```json
{
    "pizzaTaste": "pizza tastes %{taste}",
    "good": "good",
    "great": "great"
}
```

```ts
const good = Locales.format('good');
const great = Locales.format('great');

Locales.format('pizzaTaste', {taste: good});
// outputs "pizza tastes good"

Locales.format('pizzaTaste', {taste: great});
// outputs "pizza tastes great"
```

## Handling plurals

The plural version of the same string can be handled using the `smart_count` option.

First we have to create a string that has both the singular and the plural version separated by the `||||` symbol: `singular |||| plural`

```json
{
    "pizzaSlice": "pizza slice |||| pizza slices"
}
```

```ts
Locales.format('pizzaSlice', {smart_count: 0});
// 0 is plural in English, outputs "pizza slices"

Locales.format('pizzaSlice', {smart_count: 1});
// 1 is singular in English, outputs "pizza slice"

Locales.format('pizzaSlice', {smart_count: 2});
// 2 is plural in English, outputs "pizza slices"
```

## Combining formatting options

Combining multiple formatting string options can get pretty powerful.

```json
{
    "pizzaSlice": "a pizza slice tastes %{taste} |||| %{smart_count} pizza slices taste %{taste}",
    "good": "good",
    "better": "better"
}
```

```ts
const good = Locales.format('good');
const better = Locales.format('better');

Locales.format('pizzaSlice', {smart_count: 1, taste: good});
// outputs "a pizza slice tastes good"

Locales.format('pizzaSlice', {smart_count: 3, taste: better});
// outputs "3 pizza slices taste better"
```

## React advanced use-case

Sometimes you need to have basic HTML markup in your localized strings. One way of doing this is by using `dangerouslySetInnerHTML` but as the name implies it can cause security issues. **Every user input must be escaped.**

```json
{
    "stringWithHTML": "Hello <strong>%{firstName}</strong>!"
}
```

```tsx
export const MyComponent: FunctionComponent<{user: User}> = ({user}) => (
    <>
        <div
            id="GOOD"
            dangerouslySetInnerHTML={{
                __html: Locales.format('stringWithHTML', {firstName: _.escape(user.firstName)}),
            }}
        />
        <div
            id="BAD"
            dangerouslySetInnerHTML={{
                __html: Locales.format('stringWithHTML', {firstName: user.firstName}),
            }}
        />
    </>
);
```

Instead, you should rely on the `Translation` component from the `core/locales` package. It is based on [react-i18next's Trans component](https://github.com/i18next/react-i18next/blob/master/src/TransWithoutContext.js) and automatically handles escaping.

To add a child you simply have to use placeholder components (`<0>...</0>`, `<1>...</1>`, etc) in the string:

```json
{
    "stringWithHTML": "Hello <0>%{firstName}</0>!",
    "errorDescription": "Your test organization <0>%{organizationName}<0> has limited performances. Consider <1>creating a trial organization</1> if you need more flexible limits."
}
```

```tsx
import {Translation} from '@core/locales';
import {Locales} from '../strings/Locales';

export const MyComponent: FunctionComponent<{user: User}> = ({user}) => (
    <div>
        <Translation t={Locales} i18nKey="stringWithHTML" options={{firstName: user.firstName}}>
            <strong />
        </Translation>

        <Translation t={Locales} i18nKey="errorDescription" options={{organizationName: 'Potato'}}>
            <strong />
            <a target="_blank" href="https://www.coveo.com/en/free-trial" />
        </Translation>
    </div>
);
```
