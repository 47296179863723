---
title: '@core/feature-flags'
metaTitle: 'Admin-UI | Core - Feature flags package'
metaDescription: 'How to register your feature flag and test it'
---

Feature flags provides a way to isolate new and upcoming features.

## Getting Started

**Installation**

```bash
cd ./your-package

pnpm add "@core/feature-flags@*"

or

pnpm add "@core/feature-flags@*" --recursive --filter <your-package>
```

## User Guide

### How can I register feature flags?

```typescript
import {UserFeatureFlags} from '@core/feature-flags';

export const FeatureFlags = {
    userFlagA: 'user-flag-a',
    userFlagB: 'user-flag-b',
};

UserFeatureFlags.register(FeatureFlags);

// or

UserFeatureFlags.register(FeatureFlags.userFlagA);
UserFeatureFlags.register(FeatureFlags.userFlagB);
```

### How do I know if my feature flag is on?

```typescript
import {UserFeatureFlags} from '@core/feature-flags';

import {FeatureFlags} from './feature-flags';

const MyMethod = () => {
  if (UserFeatureFlags.hasFlag(FeatureFlags.userFlagA)) {
  ...
  }
}
```
