---
title: 'Creating a LaunchDarkly flag'
metaTitle: 'Admin-UI Docs | Learn the basics > Creating a LaunchDarkly flag'
metaDescription: 'How to leverage the LaunchDarkly flags in the Admin-UI project'
---

## When to Use It

-   User testing
-   Gradual rollout
-   Fix a reproducible bug only in production

1. Set your flag in the [admin-ui](https://github.com/coveo/admin-ui/blob/master/terraform/launchdarkly/launchdarkly.tf) repository in the `terraform/launchdarkly/launchdarkly.tf` file.

    ```hcl
    resource "launchdarkly_feature_flag" "my-flag" {
        project_key    = local.project_key
        key            = "your-feature-flag-key"
        name           = "Your feature flag name"
        description    = "Your feature flag short description"
        variation_type = "boolean"
        tags           = ["some", "tags"]
        temporary      = true

        variations {
            value = false
        }

        variations {
            value = true
        }

        defaults {
            off_variation = 0
            on_variation  = 1
        }

        client_side_availability {
            using_environment_id = true
        }
    }
    ```

    The option [client_side_availability](https://registry.terraform.io/providers/launchdarkly/launchdarkly/latest/docs/resources/feature_flag#client_side_availability) is required so that the flag is available in the client-side SDKs (like the JavaScript SDK).
    If you already created your flag without this option, you can do a PR to add the value.

    You should always validate the correct Terraform implementation of the flag with [Terragrunt frontend (TGF)](https://coveord.atlassian.net/wiki/spaces/CI/pages/796296129/How+to+use+TGF) before submitting a pull request to add the flag.

    ```console
    cd terraform/launchdarkly
    tgf plan
    ```

2. Open the `FeatureFlags.ts` file of your working package under `./packages/{packageName}/FeatureFlags.ts`
3. Add your key in the `LaunchDarklyFlags` object

    ```tsx
    export const LaunchDarklyFlag = {
        yourFeatureFlag: 'your-feature-flag-key',
    };
    ```

4. Use `LaunchDarklyFeatureFlags.isActive` to conditionally check if the flag is enabled

    ```tsx
    const MyComponent: FunctionComponent = () => (
        <Guard
            canRender={LaunchDarklyFeatureFlags.isActive(LaunchDarklyFlag.yourFeatureFlag)}
            fallback={<ComponentToRenderWhenInactive />}
        >
            <ComponentToRender />
        </Guard>
    );
    ```

## Toggle a LaunchDarkly Flag

### Via code (tgf)

You can use a `launchdarkly_feature_flag_environment` to toggle the flag or enable it per organization, directly in the tf file, thus controlling its state via code

By using terraform files you have all the possibilities you have via the UI with all the benefits of code reviews.

#### By organization

To enable the flag for specific orgs you can use the `targets` object

```hcl
# launchdarkly.tf
resource "launchdarkly_feature_flag_environment" "my-flag-configuration" {
    flag_id        = launchdarkly_feature_flag.my-flag.id
    env_key       = var.env
    on            = true
    off_variation = 0

    targets {
        values    = var.ld-my-flag-orgs
        variation = 1
    }

    fallthrough {
        variation = 0
    }
}

# _variables.tf
variable "ld-my-flag-orgs" {
    default = []
    type    = list(string)
}

# env-dev.tfvars or env-stg.tfvars or ...
ld-my-flag-orgs = ["aduiorgtestdonotdeletepleaseas62tcf4"]
```

#### By environment

It's also possible to enable the flag per environment by changing the fallthrough

```hcl
# launchdarkly.tf
resource "launchdarkly_feature_flag_environment" "my-flag-configuration" {
    // ...
    fallthrough {
        variation = var.ld-my-flag-default-fallthrough
    }
}

# _variables.tf
variable "ld-my-flag-default-fallthrough" {
    default = 0
    type    = number
}

# env-dev.tfvars or env-stg.tfvars or ...
ld-my-flag-default-variation = 1
```

#### By organization attributes

You can even enable the flag based on license type, product type or product edition

```hcl
# launchdarkly.tf
resource "launchdarkly_feature_flag_environment" "my-flag-configuration" {
    // ...
    targets {
        values    = var.ld-my-flag-orgs
        variation = 1
    }

    fallthrough {
        variation = var.ld-my-flag-default-variation
    }

    rules {
    variation = 1

    clauses {
      attribute = "licenseProductType"
      op        = "in"
      values    = var.my-flag-product-types
      negate    = false
    }
  }
}

# _variables.tf
variable "my-flag-product-types" {
    default = []
    type    = list(string)
}

# env-dev.tfvars or env-stg.tfvars or ...
my-flag-product-types = ["TRIAL", "INTERNAL", "TEST"]
```

#### Mixed targeting

All those options can be combined to create more specific rules. E.g.,

-   Enable the flag in dev and stg for all orgs
-   Enable it in production for a specific set of org and all trials

```hcl
# launchdarkly.tf
resource "launchdarkly_feature_flag_environment" "my-flag-configuration" {
    // ...
    rules {
    variation = 1

    clauses {
      attribute = "licenseProductType"
      op        = "in"
      values    = var.my-flag-product-types
      negate    = false
    }
  }
}

# _variables.tf
variable "ld-my-flag-orgs" {
    default = []
    type    = list(string)
}
variable "my-flag-product-types" {
    default = []
    type    = list(string)
}
variable "ld-my-flag-default-variation" {
    default = 0
    type    = number
}

# env-dev.tfvars
ld-my-flag-default-variation = 1

# env-stg.tfvars
ld-my-flag-default-variation = 1

# env-prd.tfvars
ld-my-flag-orgs = ["coveointernal", "coveointernal2"]
my-flag-product-types = ["TRIAL"]
```

### Via the UI (deprecated)

You can also manage it via the UI.

_Note that if a flag has a `launchdarkly_feature_flag_environment`, new deployments of the admin-ui will override your changes_

1. Open the [LaunchDarkly website](https://app.launchdarkly.com/) and log in. (_You need the credentials to do so._)
2. Select The environment in which you want to toggle the flag with the dropdown in the top left of the service.<br/>
   ![Feature flags env](./images/launchdarkly-flags-environment.png)
3. Select `Feature Flags` in the side nav and :
    - Click on The toggle if you want to enable it for all orgs at once in the selected environment.
    - Click on the feature flag name to enter the menu if you want to fine tune the orgs in which you want to enable the flag.

_In the LaunchDarkly platform, an `organization` is defined as a `user`._

1. Chose your environment.
2. Open the feature flag by clicking on its link.
3. **Important**: If you want to exclude by default, set the `Default rule` to `SERVE: false`.

#### By Organizations

1. Click on `+ Add user targets` under `Target individual users`.
2. Add any org Id you want to enable the flag for under `True`.
3. Add any org you want to exclude the flag for under `False`.

#### By Rules

1. Click on `+ Add rule` under `Target users who match these rules`.
2. Select your `rule`, an `Operator`, some `values` and serve `true` or `false` according the condition.

## Remove a Flag

1. Remove the entry in the `LaunchDarklyFlags` object of the `FeatureFlags.ts` file of your working package.
2. Remove all the conditions that check the flag validation.
3. Do a Pull Request with everything at once in the admin UI repository.
4. Remove the feature flag in the `admin-ui/terraform/launchdarkly/launchdarkly.tf` flag.
