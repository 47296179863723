import {AppShell, ExternalAnchor, NavLink, ScrollArea, Skeleton, Stack} from '@components/mantine';
import {UserMenu} from '@components/session';
import {PlatformNoHandlers, useQuery} from '@core/api';
import {doLogout} from '@core/authentication';
import {UserSize24Px} from '@coveord/plasma-react-icons';
import {Link, useRouterState} from '@tanstack/react-router';
import {FunctionComponent} from 'react';
import {buildContentTree, getAllMdIds, isContent, MdMetadata, Tree} from '../../getMdComponent';

const sortIndexFirst = (a: [string, Tree | MdMetadata], b: [string, Tree | MdMetadata]) => {
    if (a[0] === '/') {
        return -1;
    }
    if (b[0] === '/') {
        return 1;
    }
    return a[0].localeCompare(b[0]);
};

const Navigation: FunctionComponent<{tree: Tree; basePath?: string}> = ({tree: node, basePath = '/'}) => {
    const selected = useRouterState({
        select: (state) => state.location,
    });

    return Object.entries<Tree | MdMetadata>(node)
        .sort(sortIndexFirst)
        .map(([key, value]) => {
            if (isContent(value)) {
                if (key === '/') {
                    return (
                        <NavLink
                            key={key}
                            component={Link}
                            to={basePath}
                            label={value.title}
                            activeOptions={{exact: true}}
                            activeProps={() =>
                                ({
                                    'data-active': true,
                                }) as any
                            }
                        />
                    );
                } else {
                    return (
                        <NavLink
                            key={key}
                            component={Link}
                            to={`${basePath}${key}`}
                            label={value.title}
                            activeProps={() =>
                                ({
                                    'data-active': true,
                                }) as any
                            }
                        />
                    );
                }
            }

            return (
                <NavLink
                    key={key}
                    label={(value?.['/'] as MdMetadata)?.title ?? key}
                    defaultOpened={selected.pathname?.startsWith(basePath + key)}
                >
                    <Navigation tree={value} basePath={basePath + key + '/'} />
                </NavLink>
            );
        });
};

export const SideNavigation = () => {
    const {data: user, isLoading: isUserLoading} = useQuery({
        queryKey: ['user'],
        queryFn: () => PlatformNoHandlers.user.get(),
    });
    const logout = () => doLogout();

    const allMds = getAllMdIds(null);
    return (
        <AppShell.Navbar p="md" pr="0">
            <AppShell.Section grow component={ScrollArea}>
                <Navigation tree={buildContentTree(allMds)} />
            </AppShell.Section>
            <AppShell.Section pt="md">
                <Stack gap="xs" pl="sm">
                    <ExternalAnchor href="https://coveo.slack.com/archives/C01M10DME91">Slack</ExternalAnchor>
                    <ExternalAnchor href="https://github.com/coveo-platform/admin-ui">GitHub</ExternalAnchor>
                    <ExternalAnchor href="https://plasma.coveo.com">Plasma</ExternalAnchor>
                    <ExternalAnchor href="https://github.com/coveo/platform-client">Platform Client</ExternalAnchor>
                </Stack>
            </AppShell.Section>
            <AppShell.Section mt="sm">
                <Skeleton visible={isUserLoading}>
                    <UserMenu
                        position="right-end"
                        offset={8}
                        showInternalSection={false}
                        userEmail={user?.email}
                        userProvider={user?.provider}
                        userDisplayName={user?.name}
                        logoutEvent={logout}
                        customMenuTarget={
                            <NavLink label={user?.name} noWrap leftSection={<UserSize24Px height={24} />} />
                        }
                    />
                </Skeleton>
            </AppShell.Section>
        </AppShell.Navbar>
    );
};
