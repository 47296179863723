import {Anchor, Blockquote, Image, List, MantineTable, Stack, Text, Title} from '@components/mantine';
import {CodeHighlight, InlineCodeHighlight} from '@mantine/code-highlight';
import type {MDXComponents} from 'mdx/types';
import {Link} from '@tanstack/react-router';
import {AnchorTag} from './AnchorTag';
import classes from './HeadingAnchor.module.css';

const appendString = (children) => {
    if (!Array.isArray(children)) {
        return children;
    }

    return children.reduce((acc, current) => {
        if (typeof current === 'string') {
            return acc.concat(current);
        }

        if (typeof current === 'object') {
            return acc.concat(current.props.children);
        }

        return acc;
    }, '');
};

const Heading = ({children, order, ...props}) => {
    const id = appendString(children)
        .replace(/[\s"'?\.]+/g, '-')
        .toLowerCase();
    return (
        <Title order={order} id={id} className={classes.title} {...props}>
            <Anchor component={Link} hash={id} inherit className={classes.titleAnchor}>
                {children}
            </Anchor>
        </Title>
    );
};

export default {
    wrapper: ({children}) => (
        <Stack p="xl" gap="sm">
            {children}
        </Stack>
    ),
    h1: ({children, ...props}) => (
        <Heading {...props} order={2}>
            {children}
        </Heading>
    ),
    h2: ({children, ...props}) => (
        <Heading {...props} order={3}>
            {children}
        </Heading>
    ),
    h3: ({children, ...props}) => (
        <Heading {...props} order={4}>
            {children}
        </Heading>
    ),
    h4: ({children, ...props}) => (
        <Heading {...props} order={5}>
            {children}
        </Heading>
    ),
    h5: ({children, ...props}) => (
        <Heading {...props} order={6}>
            {children}
        </Heading>
    ),
    h6: ({children, ...props}) => (
        <Heading {...props} order={6}>
            {children}
        </Heading>
    ),
    p: ({children}) => <Text>{children}</Text>,
    code: ({ref: _ref, children, className, ...others}) => {
        const language = className?.split('-')[1];
        if (typeof children === 'string' && children.includes('\n')) {
            return <CodeHighlight code={children} language={language} {...others} />;
        } else if (typeof children === 'string') {
            return <InlineCodeHighlight code={children} language={language} {...others} />;
        } else {
            throw new Error('Code block must be a string');
        }
    },
    // code: CodeBlock,
    a: ({children, href, ...others}) => (
        <AnchorTag href={href} {...others}>
            {children}
        </AnchorTag>
    ),
    strong: ({children}) => (
        <Text span fw={500}>
            {children}
        </Text>
    ),
    ul: ({children}) => <List fz="sm">{children}</List>,
    ol: ({children}) => (
        <List type="ordered" fz="sm">
            {children}
        </List>
    ),
    li: ({children}) => <List.Item>{children}</List.Item>,
    img: ({ref: _ref, ...props}) => <Image maw="100%" {...props} />,
    blockquote: ({children}) => <Blockquote>{children}</Blockquote>,
    table: ({children}) => <MantineTable>{children}</MantineTable>,
    tbody: ({children}) => <MantineTable.Tbody>{children}</MantineTable.Tbody>,
    thead: ({children}) => <MantineTable.Thead>{children}</MantineTable.Thead>,
    tr: ({children}) => <MantineTable.Tr>{children}</MantineTable.Tr>,
    td: ({children}) => <MantineTable.Td>{children}</MantineTable.Td>,
    th: ({children}) => <MantineTable.Th fw={500}>{children}</MantineTable.Th>,
    tfoot: ({children}) => <MantineTable.Tfoot>{children}</MantineTable.Tfoot>,
} satisfies MDXComponents;
