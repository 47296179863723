---
title: 'Creating a UI feature flag'
metaTitle: 'Admin-UI Docs | Learn the basics > Creating a UI feature flag'
metaDescription: 'How to leverage UI feature flags in the Admin-UI project'
---

## When to implement

Implementing a feature flag should be the first step when the user story you are working on significantly adds to or modifies the user interface.

_e.g., A new panel, a new UX flow for an existing panel, etc._

## When to use it

-   Active development
-   Proof of concept

## How to implement

1. Open the `FeatureFlags.ts` file of your working package under `./packages/{packageName}/FeatureFlags.ts`
2. Add your key in the `FeatureFlags` object

    ```tsx
    import {UserFeatureFlags} from '@core/feature-flags';

    export const FeatureFlags = {
        yourFeatureFlag: 'your-feature-flag-key',
    };

    UserFeatureFlags.register(FeatureFlags);
    ```

3. Use the `UserFeatureFlags.hasFlag` function to conditionally check if the flag is enabled

    ```typescript jsx
    import {UserFeatureFlags} from '@core/feature-flags';

    // ...

    if (UserFeatureFlags.hasFlag(FeatureFlags.yourFeatureFlag)) {
        return <ComponentToRender />;
    }
    ```

## Removal of a Feature Flag

1. Remove the entry in the `FeatureFlags` object of the `FeatureFlags.ts` file of your working package.
2. Remove all the conditions that check the flag validation.
3. Do a Pull Request with everything at once in the Admin UI repository.

## How to test

When you are ready to test your changes under a [UI Feature Flag](/guides/ui-feature-flags), you must remember to mock the Feature flag method so that your changes are reflected in the testing environment as it mimics the person turning it on.

### RTL with a Feature Flag

For example your component might look like this:

```tsx
import {FunctionComponent} from 'react';
import {UserFeatureFlags} from '@core/feature-flags';
import {FeatureFlags} from '../../../src/FeatureFlags';

export const SourceTypeChooser: FunctionComponent = () => {
    if (UserFeatureFlags.hasFlag(FeatureFlags.SomeNewFeature)) {
        return <NewFeatureComponent />;
    }

    return (
        <div className="source-type-chooser">
            <GetStarted />
        </div>
    );
};
```

You must `spyOn` and mock the implementation of the the `UserFeatureFlags.hasFlag()` method before your `render` so that you can see your `NewFeatureComponent` rendered in the test.

Your test will look like something below which will render your component as expected.

```tsx
import {SourceTypeChooser} from '../SourceTypeChooser';
import {UserFeatureFlags} from '@core/feature-flags';
import {FeatureFlags} from '../../../src/FeatureFlags';

describe('SourceTypeChooser', () => {
     it('will render the modal when the feature flag is enabled for the choices' () => {

        jest.spyOn(UserFeatureFlags, 'hasFlag').mockImplementation(
            (val: string) => val === FeatureFlags.SomeNewFeature
        );

        render(<SourceTypeChooser />);

        expect(...NewFeatureComponent).toBeInTheDocument();
    });
});


```

> Please note, we previously mocked feature flags like: `jest.spyOn(UserFeatureFlags, 'hasFlag').mockReturnValue(true)`, the method above is more direct and safer to use as you will see why in the next example when a component has more than one feature flag.

### RTL with multiple Feature Flags in a component

Lets say the component has multiple Feature flags and you want to test the `<CoolerFeature />`

```tsx
import {FunctionComponent} from 'react';
import {UserFeatureFlags} from '@core/feature-flags';
import {FeatureFlags} from '../../../src/FeatureFlags';

export const SourceTypeChooser: FunctionComponent = () => {
    if (UserFeatureFlags.hasFlag(FeatureFlags.SomeNewFeature)) {
        return <NewFeatureComponent />;
    }

    if (UserFeatureFlags.hasFlag(FeatureFlags.SomeOtherCoolFeature)) {
        return <CoolerFeature />;
    }

    return (
        <div className="source-type-chooser">
            <GetStarted />
        </div>
    );
};
```

You can use the `mockImplementation` method in Jest with this pattern below:

```tsx
import {FunctionComponent} from 'react';
import {UserFeatureFlags} from '@core/feature-flags';
import {FeatureFlags} from '../../../src/FeatureFlags';

describe('SourceTypeChooser', () => {
    it('render should render the CoolerFeature', async () => {
        jest.spyOn(UserFeatureFlags, 'hasFlag').mockImplementation(
            (val: string) => val !== FeatureFlags.SomeNewFeature,
        );

        jest.spyOn(UserFeatureFlags, 'hasFlag').mockImplementation(
            (val: string) => val === FeatureFlags.SomeOtherCoolFeature,
        );

        render(<SourceTypeChooser />);

        expect(...CoolerFeature).toBeInTheDocument();
    });
});
```

`mockImplementation` will make the `FeatureFlag.SomeNewFeature` return false and make the `FeatureFlags.SomeOtherCoolFeature` return true so that you can render the `<CoolerFeature />` component.
