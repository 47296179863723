---
title: 'Appendix 4 - Updating dependencies'
metaTitle: 'Admin-UI Docs | Getting started > Appendix 4 - Updating dependencies'
metaDescription: 'How to manually update our internal dependencies'
---

## To update specific dependencies

-   To update Plasma to the latest version in the package.json and lock file.

    ```bash
    pnpm update:plasma
    ```

-   To update `@coveo/platform-client` in to the latest version in the package.json and lock file.

    ```bash
    pnpm update:platform-client
    ```

-   To update `@coveo/platform-client` to a specific version in the package.json and lock file.

    ```bash
    pnpm update --recursive @coveo/platform-client@20.7.0
    ```

-   To update a dependency to a specific or the latest version in all the main packages in both the package.json and the lock file.

    ```bash
    pnpm update --recursive packageName@version
    # or
    pnpm update --recursive packageName --latest
    ```

## To update all dependencies in the pnpm-lock.yaml file according to the version specified in the package.json files

Any update of the dependencies or version changes done in package.json files require the pnpm-lock.yaml file to be updated (regenerated) and committed. This file is at the root of the repo and is unique.

In order to do this, follow the steps below.

1. Make sure `pnpm config get package-lock` command outputs `true`. If not, run the following command:

    ```bash
    pnpm config set package-lock true
    ```

2. Update the `pnpm-lock.yaml` file.

    ```bash
    pnpm setup
    # or
    pnpm install
    # or
    pnpm i
    ```

3. Commit the updated `pnpm-lock.yaml` file to the repository.

---

## Quick tips for the manual bumps of pnpm packages

-   You can make a pull request with multiple dependecies update at once.
-   If possible, you should have the same version of a dependency in all packages.
-   Make sure the bump is not breaking a functionality, a green build is not a clear indication that nothing is broken.
-   Bump only minor version of a dependency. Because of the possible breaking changes, the bump of a major version should be linked to a Jira/pr and be monitored more closely.
-   Don't forget to bump dependencies in [Plasma](https://github.com/coveo/plasma) from time to time 😋
-   [List of deprecating libraries and patterns in Admin-UI](https://coveord.atlassian.net/wiki/spaces/RD/pages/2881847348/Admin+UI+Dependencies+and+Patterns+Deprecations)

### 🚨 Here's a list of dependencies that you should **NOT** bump: 🚨

In Admin-UI

-   backbone
-   backbone.marionette
-   jQuery

In [Plasma](https://github.com/coveo/plasma)

-   tsjs
-   anything react-dnd
-   rc-slider
