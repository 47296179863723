---
title: 'Continuous delivery'
metaTitle: 'Admin-UI Docs | Getting started > Continuous delivery'
metaDescription: 'Explains how Continuous delivery works and best practices'
---

As of November 1st 2022, Continuous delivery was enabled for the admin-ui project.

This means any change merged in master will trigger a deployment in the development, staging, production and HIPAA environment automatically, as long as all certifiers pass.

This change to the deployment process will reduce the number of bugs, make it possible to ship faster and reduce the complexity of the deployments. The R&D units will have more power over what and when they deliver features and bugfixes.

# Good practices

# 1. Feature flags should be used for new features

Like we mention in our Best practices, you should always start by asking yourself if a [UI Feature Flag](/guides/ui-feature-flags) or [LaunchDarkly flag](/guides/launch-darkly-flag) should be used for your feature.

**Note:** When testing code under a feature flag, make sure to look into the feature behavior both **with** and **without** the flag enabled.

# 2. Your pull request should always include a JIRA ticket

Each pull request should be linked to a JIRA ticket. Is it recommended to include your JIRA key in the branch name, but it can also be added in the pull request title if it was forgotten. More information on JIRA issue discovery can be found [here](https://coveord.atlassian.net/wiki/spaces/CM/pages/1786708612/Package+Rollout+Certifier#Jira-Issue-Discovery).

# 3. Your pull request should be properly tested

When creating your pull request, a demo link will be generated. You and your reviewers should use that link to test the code before merging to development. Adding a comment to list what was tested also helps confirm we have good testing coverage.

If the code does not require further testing, the **demo tested** label can be used by a reviewer. This will automatically set the **QA Testable** field on the related JIRA ticket to _Was tested_, which is a valid value for automated approval.<br/>
![demo tested](./images/continuous-delivery-demo-tested.png)

# 4. Features should be covered by automated tests

Features should be covered by automated tests before they are made available to clients on production. This can be done using the [Playwright end-to-end framework](https://github.com/coveo/admin-ui/tree/master/e2e) included in admin-ui.

Relevant end-to-end tests can be run in your pull request by expanding the End-to-end section of the template. You just need to select which tests you want to run based on the listed tags.<br/>
![e2e tags](/images/content/continuous-delivery-e2e-tags.png)

Once selected, a new optional check will start. This will launch the end-to-end test on chrome using your pull request code, in the demo branch. You can see its result and Details here.<br/>
![e2e tags](/images/content/continuous-delivery-e2e-check.png)

> Note : when running multiple tags, it's better to Edit your pull request description and 'x' the tags you want to select. This will send a single request with all tags.

More information on Playwright can be found in the [Playwright Guidelines](/tests/playwright-guidelines).

# 5. JIRA fields should be configured

According to the deployment pipeline [guidelines](https://coveord.atlassian.net/wiki/spaces/CM/pages/1786708612/Package+Rollout+Certifier#Jira-issue-fields-to-validate), the **QA Testable** and **Requires Public Documentation** fields should be set with the right value in your JIRA tickets.<br/>

If **QA Testable** is set to _Yes_, or if both fields are _left empty_, your pull request will be on hold until the issue is tested and the documentation field is set to either _Done_ or _No_. **The sooner those fields are set, the better!** If you already know about testing or documentation requirements, you can always set the values in JIRA before creating your pull request.

A **compliance check** is integrated into pull requests to prevent merging code that does not meet those requirements. This check must be executed at least once per pull request, and redone on every new commit. To execute it, click the Compliance checkbox in the pull request description under the Compliance section.<br/>

When the check fails, you can edit the JIRA fields with the right values or use the **demo tested** label mentioned above.<br/>

![compliance check error](./images/continuous-delivery-compliance-check-error.png)

Once fixed, you can click the Compliance checkbox to re-run the compliance check.<br/>

![compliance checkbox](./images/continuous-delivery-compliance-checkbox.png)

The goal of the compliance check is to prevent delays and improve efficiency when rolling out code.

# FAQ

# How often do we deploy?

When merging code, a workflow is started in the [GitHub actions](https://github.com/coveo/admin-ui/actions). These workflows will get queued automatically. Once no workflow is ongoing, a new package will be deployed to development. This package will then be deployed to staging, and all the deployment pipeline certifiers will run. If all certifiers pass (including the package rollout which looks for invalid JIRA tickets), the deployment to production and HIPAA will automatically start. This means deployment frequency depends on how many pull requests are being merged and queued, and if all certifiers pass or not.

**On average, there are 3 to 6 deployments to development per day.**

# Where can I see deployments ?

You can find all the deployment notifications in Slack, under the [**#admin-ui-builds**](https://coveo.slack.com/archives/CT3DGPG8H) channel. Each thread will show when a deployment starts and ends for every environment, up to HIPAA. If a package rollout contains invalid JIRA tickets, it will also be shown here.<br/>
![slack thread](./images/continuous-delivery-admin-ui-builds.png)

If you prefer using JIRA, the following [filter](https://coveord.atlassian.net/issues/?filter=34517) can be used to see all packages deployed in the past month. You can open any package to see it's content and state.

# What's blocking the deployment?

The one thing most likely blocking deployments are JIRA tickets with invalid values, or values that require testing. Failing certifiers (Snyk vulnerability, issue in automated test) could also be the cause. Like mentioned above, those failures will be shown in the **#admin-ui-builds** channel.

When deployments to production are blocked, the development and staging environment will still be updating. This means other fixes and features can be added to the blocked deployment, and will be deployed to production once everything is unblocked.

# Where is my feature?

There a multiple ways a feature can be tracked on JIRA, all within the ticket.

You can inspect the **Releases** field in the Details section of the ticket. The field will go from _Development_ to _Staging_ and lastly _Production_. <br/>
![Releases](./images/continuous-delivery-releases.png)

You can also inspect the **Linked issues** sections, where you will see all packages containing the ticket. If the latest package is _Completed_, it means the issue was successfully deployed to Production. This can also give you an idea of how many packages contains the ticket: more than one package would mean it was blocked in Staging.
![Linked issues](./images/continuous-delivery-linked-issues.png)
