---
title: 'Admin-UI Project'
metaTitle: 'Admin-UI Docs'
metaDescription: 'The documentation home page.'
---

This repository holds the source code of the Coveo Administration Console's user interface (also known as _Admin UI_, _ADUI_ or _jsadmin_.

[Confluence Summary](https://coveord.atlassian.net/wiki/spaces/RD/pages/35225683/Admin+UI+ADUI)

![Admin-UI console](./images/project-admin-ui.png)

> You can run a full-fledged version of the Administration Console locally using only the [AdminUI repository](https://github.com/coveo/admin-ui).

## Prerequisites

-   Node.js ([use the current Long Term Support](https://nodejs.org/en/about/releases/))
-   pnpm >= 8.11.0
