---
title: 'Appendix 2 - VSCode extensions suggestions'
metaTitle: 'Admin-UI Docs | Getting started > Appendix 2 - VSCode extensions suggestions'
metaDescription: 'VSCode extensions to help you become more productive!'
---

Once you are done installing the mandatory extensions (ESLint and Prettier), you can start looking into other ones to make your life easier or just make your VSCode nice to look at.

Here's a non-exhaustive list of our favorite extensions:

## Must have / Highly recommended

1. [IntelliCode](https://marketplace.visualstudio.com/items?itemName=VisualStudioExptTeam.vscodeintellicode)
2. [GitLens — Git supercharged](https://marketplace.visualstudio.com/items?itemName=eamodio.gitlens)
3. [Path Intellisense](https://marketplace.visualstudio.com/items?itemName=christian-kohler.path-intellisense)
4. [Git History](https://marketplace.visualstudio.com/items?itemName=donjayamanne.githistory)

## Quality of life improvements

Bracket matching is now built-in, you can [turn in on in the settings directly](https://code.visualstudio.com/docs/editor/editingevolved#_bracket-pair-colorization)

1. [Auto Close Tag](https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-close-tag)
2. [Color Highlight](https://marketplace.visualstudio.com/items?itemName=naumovs.color-highlight)
3. [npm Intellisense](https://marketplace.visualstudio.com/items?itemName=christian-kohler.npm-intellisense)
4. [Auto Rename Tag](https://marketplace.visualstudio.com/items?itemName=formulahendry.auto-rename-tag)
5. [indent-rainbow](https://marketplace.visualstudio.com/items?itemName=oderwat.indent-rainbow)
6. [Trailing Spaces](https://marketplace.visualstudio.com/items?itemName=shardulm94.trailing-spaces)
7. [Better Comments](https://marketplace.visualstudio.com/items?itemName=aaron-bond.better-comments)
8. [Turbo Console Log](https://marketplace.visualstudio.com/items?itemName=ChakrounAnas.turbo-console-log)
9. [Relative Path](https://marketplace.visualstudio.com/items?itemName=jakob101.RelativePath)
10. [Auto Import](https://marketplace.visualstudio.com/items?itemName=steoates.autoimport)
11. [Path Autocomplete](https://marketplace.visualstudio.com/items?itemName=ionutvmi.path-autocomplete)

## Languages related

1. [Sass](https://marketplace.visualstudio.com/items?itemName=Syler.sass-indented)
2. [YAML](https://marketplace.visualstudio.com/items?itemName=redhat.vscode-yaml)
3. [Markdown All-In-One](https://marketplace.visualstudio.com/items?itemName=yzhang.markdown-all-in-one)
4. [DotENV](https://marketplace.visualstudio.com/items?itemName=mikestead.dotenv)
5. [TypeScript Hero](https://marketplace.visualstudio.com/items?itemName=rbbit.typescript-hero)

## Some VSCode life hacks

```json
"breadcrumbs.enabled": false, //removes breadcrumbs under file
"editor.minimap.enabled": false //removes the minimap (hardly used and not so useful)
"workbench.startupEditor": false, //removes the early startup editor of VScode, annoying popups sometimes
"editor.renderIndentGuides": false, //removes the indentGuide, makes vscode slightly cleaner
"workbench.editor.showTabs": false, // I was very skeptical of this but the use of CMD/Ctrl + P
// is much preferred to navigate files, this also reduces the visual cognitive load while working
// as you end up with a bunch of tabs open which clutters your workspace
```
