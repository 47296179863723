---
title: 'API calls'
metaTitle: 'Admin-UI Docs | Learn the basics > API calls'
metaDescription: 'Learn how to interact with Coveo Apis'
---

## The platform-client

[Platform-Client](https://github.com/coveo/platform-client) is an object oriented tool created by Coveo and maintained by [the Developer eXperience (DX) team](https://github.com/orgs/coveo/teams/dx/members) to interact with Coveo's [RESTful resources](https://platformdev.cloud.coveo.com/docs?urls.primaryName=Platform).

The Platform Client class exposes the main Coveo public API endpoint's REST resources that you can find in the [Swagger Documentation](https://platformdev.cloud.coveo.com/docs?urls.primaryName=Platform). Find in the official [Platform-client repository](https://github.com/coveo/platform-client/tree/master/src/resources) the list of the resources already implemented and ready to be used.

### Usage

#### Calling a resource

interacting with a resource by using the **platform-client** is simple. Calling the function will return a promise, just like it would with **fetch**.

```
import {Platform} from '@core/api';

Platform.source.list();
Platform.pipeline.conditions.create(CondidionModel);
```

#### Platform vs PlatformNoHandlers

`import {Platform} from '@core/api';`

Errors when calling a resource with **Platform** will be handled and a notification toast with the error message will be rendered.

`import {PlatformNoHandlers} from '@core/api';`

Errors when calling a resource with **PlatformNoHandlers** wont be handled automatically.

#### Typescript

Types can be imported from the platform-client.

```tsx
import type {FieldModel} from '@core/api';

const handleField = (field: FieldModel) => {
    // handling
};
```

## React-Query

We enforce the use of [Tanstack Query a.k.a **React Query**](https://tanstack.com/query/v4/docs/react/overview) to manage async calls.
You can find more information about the library and their devtools in [our doc](https://docs.admin-ui.coveo.com/learn-the-basics/01-the-techstack-basics/#reactquery).

The use of **react-query** and **coveo platform-client** together simplifies the developer experience when interacting with Coveo APIs.

## Examples

Fetching a list:

```tsx
const {data: permissions} = useQuery({
    queryKey: ['fetchPermissions', index, uniqueId],
    queryFn: () => Platform.index.documents.listPermissions(index, uniqueId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, // 10 minutes
});
```

Fetching a resource conditionnaly:

```tsx
const [currentProject, setCurrentProject] = useState<ProjectModel | null>(null);
const [shouldFetch, setShouldFetch] = useState(false);

const {data: fetchedProject, isError} = useQuery({
    queryKey: ['fetchProject'],
    queryFn: () => Platform.project.get(projectId),
    enabled: shouldFetch,
});

useEffect(() => {
    if (alreadyHasProject) {
        setCurrentProject(project);
    } else {
        setShouldFetch(true);
    }

    if (fetchedProject) {
        setCurrentProject(fetchedProject);
    }
}, [fetchedProject]);
```

Creating a resource:

```tsx
const {mutateAsync} = useMutation((tokenParams: RestTokenParams) => Platform.search.createToken(params));

const handleSave = async (id) => {
    const resource = await mutateAsync({id});

    console.log(`Resource ${resource} has been created`);
};
```
