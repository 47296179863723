---
title: 'Install and launch'
metaTitle: 'Admin-UI Docs | Getting started > Install and launch'
metaDescription: 'How to install and Admin-UI locally'
---

All the commands in the instructions must be run at the root of the `admin-ui` folder.

## Install

Clone the project

```bash
git clone git@github.com:coveo/admin-ui.git
```

Install the project dependencies.

```bash
pnpm install
```

Once the operation is complete, you should see a `node_modules` folder at the root of the `admin-ui` folder.

## Running the Admin-UI

You can run the Admin-UI locally with the command below:

```bash
pnpm start
```

This command will build all the packages with `pnpm build` and `pnpm type-check`, which is required for Intellisense (code completion) to work properly.
You will then be prompted to select which packages you wish to hot reload, you can navigate with arrow keys, you can filter the list of packages by typing part of the package name, and select a package by pressing `space`. By default, only `@apps/platform` is selected, so you can continue by pressing Enter.

```cmd
What packages do you want to hot reload? (Optional)
❯◯ @apps/commerce-hub
 ◯ @apps/docs
 ◉ @apps/platform
 ◯ @components/event-filters
 ◯ @components/home-page
 ◯ @components/search-hubs-dropdown
 ◯ @components/snapshot-page
 ◯ @core/api
 ◯ @core/configuration
 ◯ @core/feature-flags
 ◯ @core/locales
 ◯ @core/organization
 ◯ @core/registry
 ◯ @core/store
 ◯ @coveord/jsadmin-activity-browser
 ◯ @coveord/jsadmin-commerce
(Move up and down to reveal more choices)
```

Once your server is started, a new window of your machine's default browser should automatically open and load your Administration Console local version.

By default, your local Administration Console build uses the backend dev endpoint. If you want to use the Quality Assurance endpoint, specify it in a URL parameter:

-   use of the API in Development: [http://localhost:9000/?dev](http://localhost:9000/?dev)
-   use of the API in Quality Assurance: [http://localhost:9000/?stg](http://localhost:9000/?stg)
-   use of the API in Local machine: `http://localhost:9000/?platform=your local backend address here`

### Alternative start up method

If you want to skip the [inqurier](https://github.com/SBoudrias/Inquirer.js/), feel free to type the package name after the start command

```bash
pnpm start snapshots activitybrowser
```

The above command will be the equivalent of selecting `@components/snapshot-page` and `@coveord/jsadmin-activity-browser`.

You can start as many as you like so that it will hot reload the Admin-UI as you develop in those packages. You also do not need to type to entire file name either.

### Running with the production endpoint

While the local admin-ui setup is capable to run using the production endpoint, it requires some additional steps to make it work. This is caused by the fact that the Authorization Server in production is not allowed to redirect to a local URL. To workaround this behavior, we simply need our user to already be authenticated in production, it will avoid the need to be redirected by the Authorization Server.

1. Open an org in [production](https://platform.cloud.coveo.com/) and copy the `coveo-access-token` from the localStorage to your clipboard.
2. Have your admin-ui local setup running in a different browser tab and open the DevTools
3. Pause the javascript execution of your local setup from the `sources` tab in the DevTools or by pressing `F8`.
4. Paste the prod `coveo-access-token` from your clipboard in the `coveo-access-token` field in the localStorage of your local setup.
5. Change the url from `http://localhost:9000/?dev#ORGID` to `http://localhost:9000/?production#ORGID` and press **ENTER**
6. You can use the org picker to switch to a different region if needed

Since the Australia region only has a [production environment](https://platform-au.cloud.coveo.com/), the above procedure will allow you to test your local changes in this region.

_You can execute the same steps to run the local setup in HIPAA (localhost:9000/?hipaa#ORGID) also._

### Some rules of thumb when starting the server

-   Use node's current LTS, you can use nvm to quickly switch node version.
-   The first time you start the project or after reconstructing your dependencies, a complete build of all the packages is mandatory.
-   Do not build all the packages each time you start the server if you do not need to.
-   To get a faster build, you should only start the package(s) you are working on at the moment.

---

## Tips and tricks

### Vite troubleshooting

If you're on Linux, you might have some issues starting the server because of Vite. In that case, you may have to increase the file descriptor limit.

```bash
# modify the file descriptor limit
ulimit -Sn 10000
# verify it changed
ulimit -Sn
```

```bash
# check the current limits
sysctl fs.inotify
# change the limits
sudo sysctl fs.inotify.max_queued_events=16384
sudo sysctl fs.inotify.max_user_instances=8192
sudo sysctl fs.inotify.max_user_watches=524288
```

If the problem persists, you can try adding `DefaultLimitNOFILE=65536` to the following lines:

-   /etc/systemd/system.conf
-   /etc/systemd/user.conf

Finally, if you're using Ubuntu Linux, you can try adding the line `* - nofile 65536` to the file `/etc/security/limits.conf`

If problems persist, Vite also offers [troubleshooting documentation](https://vitejs.dev/guide/troubleshooting.html)

### Useful commands

| Command           | Explanation                                                                                                                                                                                                                 |
| ----------------- | --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
| pnpm clean        | will remove all installed dependencies for the packages in the `app`, `components`, `core` & `packages` folders.                                                                                                            |
| pnpm reconstruct  | will remove all installed dependencies and reinstall them without discarding any pending changes. Run this if you think your current state is corrupted. (this command starts the `clean` command first).                   |
| pnpm build        | launches the construction of all the packages of the project.                                                                                                                                                               |
| pnpm test         | launches the tests of all the packages of the project.                                                                                                                                                                      |
| pnpm lint         | launches the **linter** of all the packages, even the root project, in **dry run mode** of all the packages which have the `lint` command in their `package.json` file.                                                     |
| pnpm lint:fix     | launches the **linter** of all the packages, even the root project, in **fix mode** of all the packages which have the `lint:fix` command in their `package.json` file.                                                     |
| pnpm link:package | launches the [**script to link our external libraries**](/getting-started/running-local-changes) ([Plasma](https://github.com/coveo/plasma) or [Platform Client](https://github.com/coveo/platform-client)) to this project |
| pnpm make:package | launches the **package creation script**, you can also [use the same script via Jenkins](/guides/creating-packages) to avoid creating a pull request                                                                        |

> Note: Are you changing branch of work? You can just run its commands there `pnpm install && pnpm start`. No need to delete the different `node_modules` folders.

## Some tips with pnpm

You can learn a lot more [about the different PNPM commands in their documentation](https://pnpm.io/pnpm-cli).

-   `pnpm --recursive <command> --filter '<package-name>'` allows to execute a npm script only in scoped packages.
-   `<command>` can be any npm script specified in the `package.json` file of the scoped packages
-   `<package-name>` is the name of any package under the `packages` folder, it can be repeated to run the command in more than one folder.
-   for example for build: `pnpm --recursive build --filter @core/configuration`
-   for example for tests: `pnpm --recursive test:watch --filter @core/configuration`
