---
title: 'Announcements 🎉'
metaDescription: 'Learn more about Admin-UI latest news!'
---

## June 2024

-   [Table refactor](./09-table-refactor)

## April 2024

-   [Mantine 7](./08-mantine-7)

## February 2024

-   [React 18 stability improvements](./03-react-18)
-   [Setting up SSL on your local machine](./04-setting-up-ssl-on-your-local-machine)
-   [Admin UI quality commitment](./05-admin-ui-quality-commitment)
-   [Mantine 7 Upgrade ETA](./06-mantine-7-upgrade-eta)
-   [E2E Certifier](./07-e2e-certifier)

## December 2023

-   [Feature Preview](./01-feature-preview)
-   [Node 20 LTS](./02-node-20)
