---
title: 'Contributing'
metaTitle: 'Admin-UI Docs | Getting started > Contributing'
metaDescription: 'Contributing guidelines for Admin-UI project'
---

## Guidelines

-   Make sure your changes in TypesScript files are fully tested.
-   We tend to avoid comments in our code base, we strongly prefer good naming and code structure.
-   Large number of complex changes can slow down the time to merge a Pull Request. It can be helpful to make smaller PRs in these situations.
-   A Pull Request can be a broken into multiple commits which allows for better git history and easy reverts.
-   Squash your commits

## The typical Pull Request journey

Following the Pull Request journey will make your code changes easier to review and therefore easier to merge.
Spending a bit more time in preparation can save everyone time. Be courteous as your team is taking time out of their day to review your changes.

1. If you have any doubt about what you're doing, you can poke the `admin-ui` core team members in the [#admin-ui-guild](https://coveo.slack.com/archives/C01M10DME91) Slack channel. We'll be able to guide you and implicate the UX and DOC team if needed.
2. The UX of a feature should be approved before you start coding ([#admin-ui-ux](https://coveo.slack.com/archives/GLS6Z9RPD) for questions)
3. Create yourself a branch and make the desired changes: branch name should include the JIRA you're working on eg. `fix/ADUI-1234-fix-something` or `feature/ADUI-1234-fix-something`
4. Commit your changes along with [appropriate commit messages](#commit-messages) - see below for guidelines.
5. Review your own code before you press the "Create pull request" button. You can see the diff inside GitHub and verify all the changes that you have made and remove any code that shouldn't be in there.

a. Don't submit until ready. You can push a branch if you want others to check your code without submitting a Pull Request or you can create a [draft Pull Request](https://github.blog/2019-02-14-introducing-draft-pull-requests/).

b. Sometimes a video explaining/demoing what you're changing can benefit the reviewer. Some recommendations for software include:

Linux: - use [Peek](https://github.com/phw/peek) for creating a GIF - use [Kazam](https://www.linuxbabe.com/multimedia/install-kazam-screencaster) for creating a video

MacOS: - use [native functionality](https://support.apple.com/en-us/HT208721) for creating a video

6. Submit your pull request.
7. If you would ask questions yourself about some patterns or lines of code, answer those questions right away for other people.
8. If the CI has finished building successfully, it will add a link for the demo in comment.
9. Respond to each reviewer. It's good etiquette to reply if you're resolving it.
   a. Comments are still preferred as the method of communication to keep visibility, and it helps others with learning.
   b. It can be quicker to communicate through Slack (keeps the Pull Request clean).
   c. If work is still in progress, you could convert the Pull Request into a draft at the top right under Reviewers on GitHub by selecting the `Still in Progress? Convert to draft`.
10. Click the checkbox under the Compliance section to trigger the compliance check. More info at the [Continuous delivery](/getting-started/continuous-delivery#5-jira-fields-should-be-configured) documentation.
11. Once your Pull Request gets enough approvals (at least 1 member of our team, one from UX (if it's a visual change) and if you created/changed some string someone from DOC), you can merge (or ask us to do so if you don't have the required credentials). We also suggest that you add (if not done already) your team members as reviewers to test the functionalities and style of your implementation.
12. Our automated build system will take care of the rest.
    a. If the Pull Request build fails, it can help to check why. People tend to avoid reviews of failing PRs.

> Note: I would like to know where my task is and in which it is released? [This information can be found in your task on the Jira app](https://github.com/coveo/admin-ui#how-do-i-know-if-my-task-is-deployed-and-on-which-environment).

## Reviewing code changes

-   When testing the demo, the unofficial dark mode and plugins may render things differently.
-   Avoid using GitHub "Request Change" as it slows down the merge flow. Comments need to be resolved before merge and can be equally useful.
-   Code reviews should be done in a timely manner. Part of your daily routine should include code reviews.
-   Anyone can review and comment on pull requests, even if not assigned. It can help to gain better knowledge of the project.
-   Question things you do not understand and challenge people.
-   Keep the comments on the Pull Request within the scope of the Pull Request.
-   For small changes, giving a code suggestion will generally resolve the suggestion faster than questioning the Pull Request owner choices.
-   We don't always need to hold up PRs for perfection (nitpicking). Sometimes it's good for discussion or understanding why something is done a certain way.

Always be kind in your interactions. We're all here to help each other ❤️.

## Commit messages

**Every commit message should comply with the [Conventional Commits](https://www.conventionalcommits.org/en/v1.0.0/) specification.**

-   You will have to manually write a commit message that follows the convention using your favorite method.

### Why

We use the commit messages to automatically bump the package version according to the semantic versioning notation.

## Pull request demos

Our automated build system kindly provides a demo link for each pull request, given the build is healthy (green).
When available, the links are automatically posted in our [admin-ui-builds](https://coveo.slack.com/archives/CT3DGPG8H) Slack channel.

> Front-end developers are visual creatures!
