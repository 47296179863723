---
title: 'Getting started'
metaTitle: 'Admin-UI Docs | Getting started'
metaDescription: 'A quick starting guide on navigating Admin-UI and its file structure'
---

A quick starting guide on navigating in Admin-UI and its file structure. Here we will cover our tools and technologies being used for development.

## Admin-UI Console

Coveo's adminstrative console is the web platform where customers use to manage their Coveo products.

This is what it looks like for the [`[ADUI Org] Do Not Delete PLEASE` org](https://platformdev.cloud.coveo.com/admin/#/aduiorgtestdonotdeletepleaseas62tcf4/home)

![Source panel of the Admin-UI console](./images/getting-started-admin-ui.png)

1. As a developer, you will have access to many different organizations in this dropdown. It will allow you to switch to different orgs in the dev environment, each having an organization types such as `Production`, `Test`, `Trial`
2. Under the Coveo Only, contains a list of [UI Feature flags](/guides/ui-feature-flags) that are used during the development of new features. Once you implement your feature flag, you will find it on the feature flags page. Feature flags allow us to globally trigger whether a new component is displayed.

## Folder Structure

We will take a quick glance at the folder structure and how it relates to Admin-UI console.

> Please note that this is subject to change in the future as we are currently re-working the structure of the Admin-UI folder structure.
> You can find more information and details in this [ADR-001](https://github.com/coveo/admin-ui/blob/master/docs/adr/001-root-project-architecture.md)

Working in our Admin-UI repo, you will see a structure like so, which highlights the relevant files you will be working on

```
admin-ui
│
└───components
│   └─── component-folder-1
│   └─── component-folder-2
│       │  src
│       │  package.json
│
└───cypress
│
└───packages
│   └─── jsadmin-activity-browser
│   └─── jsadmin-commerce
│   └─── jsadmin-log-browser
│   └─── jsadmin-machine-learning
│   └─── jsadmin-oauth-popup
│   └─── jsadmin-search-optimization
│   └─── jsadmin-service
│   └─── jsadmin-usage-analytics
│   └─── v2
│       └──src
│           └─── v2-component-1
│           │     └─── tests
│           └─── strings
│           │
│           │ package.json
│
│ package.json

```

Under the packages folder, we have 9 main packages that contain the code and business logic of many of our components in Admin-UI.

Each folder contains a very similar file structure to V2 which contains the component code and a folder for all the strings related to the package. Please note that we are in the process of splitting V2 completely so do not commit new code in this package. If you are unsure what to do, feel free to contact or message in the [guild channel](https://coveo.slack.com/archives/C01M10DME91).

## Legacy Code - Backbone

Our legacy code contains Backbone.js and uses a framework called Marionette.js.

At the current moment, we have both React and Backbone co-existing in our project. Admin-UI was initially created using Backbone and now is the main part of our routing system for the application.

The team decided future code and features will be developed using React. Thus, in order to migrate, we use Backbone to render React components as the first step. We will eventually reduce Backbone code in the future by refactoring old Backbone components to accomodate new features.

Backbone has a very different approach to web development compared to React; it uses an MVC (model-view-controller) approach to web design where as React is a UI rendering library that takes care of the view layer and does not have a central controller.

During the onboarding you will be able to spot the differences between a Backbone component and a React component.

## Tools

This section will list out the common tools that are being used in the Admin-UI and will list out the ones we are deprecating.

### Redux (Deprecating)

With React, you may have heard of Redux, which is a state management system that is paired with any UI layer or framework. When paired with React, we use the official Redux UI binding library called React Redux.

### React Functional Components and Hooks

You will see a mix of Class and Functional components, but as a team decision going forward, we would like to see Functional components being developed in our Admin-UI. This is because React expects Hooks to be the [primary way](https://reactjs.org/docs/hooks-faq.html#should-i-use-hooks-classes-or-a-mix-of-both) of writing new React components.

It will allow you to take advantage of Redux-Hooks which are extremely simple to use and allow for less boilerplate code as you develop.

### React Testing Library and Jest

We use [React Testing Library](https://testing-library.com/docs/react-testing-library/intro/) and [Jest framework](https://jestjs.io/) for the ease of use and integration with React for everything unit tests related.

## Development recommended extensions

There are extensions we use for our development which are extremely helpful in debugging and helping you throughout your coding journey:

1. [Redux DevTools](https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en) - allows you to see the state of the application in realtime
2. [Testing Library](https://chrome.google.com/webstore/detail/testing-playground/hejbmebodbijjdhflfknehhcgaklhano?hl=en) - allows you to select the items for your tests from the DOM

You will be using some of these tools later on the onboarding to help you debug and create unit tests.
