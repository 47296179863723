---
title: 'Add a Platform client Resource'
metaTitle: 'Admin-UI Docs | Learn the basics > Add a Platform client resource'
---

## Introduction

This is a guide to help you create a Platform client resource. If you are planning to add an internal or an experimental resource, please see [Adding experimental or internal resource](/guides/platform-client-add-resource#adding-experimental-or-internal-resources) at the bottom of this guide.

**When do you want to do this?**

-   When there's a call in Admin-UI (Redux-Thunk action) but it is not in Platform-client
-   When you need to use an endpoint that is not already exposed by the Platform-client

For those who are refactoring Admin-UI and see a Redux-Thunk action with a call, its preferred to have it in Platform client so you can leverage the `useAsync` Hook that can be used with React Functional components.

**Example of a Redux-Thunk Action**

```tsx
import {AccessLevel, Platform} from '@core/api';

const organizationUrl = `${Config.CloudPlatform.url}/rest/organizations/{organizationName}`;

export const AccessListUrls = {
    [PrivilegesHolderType.Group]: `${organizationUrl}/access/groups`,
    [PrivilegesHolderType.ApiKey]: `${organizationUrl}/access/apikeys`,
};


const fetch =
    ({
        id,
        owner,
        targetDomain,
        privilegesHolders = [PrivilegesHolderType.Group],
        isNew,
    }: FetchAccessListArguments): IThunkAction =>
    (dispatch: IDispatch) => {
        const getAccessCalls = _.map(privilegesHolders, (type: PrivilegesHolderType) => ...
```

Once this call is in Platform client, you can then use the syntatical sugar of `useAsync` for functional components like so:

```tsx
import {Loading} from '@coveord/plasma-react';

export const GroupsTable = () => {
    const {value: groupData, loading: isLoading} = useAsync(() =>
        Platform.organizationAccess.getGroups({
            accessLevel: [AccessLevel.CUSTOM, AccessLevel.VIEW_ALL, AccessLevel.NONE, AccessLevel.EDIT_ALL],
            privilegeOwner: 'PLATFORM',
            privilegeTargetDomain: 'SOURCE',
        }),
    );
    if (isLoading) {
        return <Loading />;
    }
    return <Table<RowData> data={groupData} columns={columns} />;
};
```

Where `groupData` is the value being returned from the API call, and you have access to `isLoading` from the `useAsync` Hook when the data is being fetched.

In this guide we will look at how the above Redux-Thunk action was added to Platform client.

# 1. Finding the call in Swagger

In the Redux Thunk example above, we see that the URL calls are:
`/rest/organizations/{organizationId}/access/groups`
`/rest/organizations/{organizationId}/access/apikeys`

You can find this Swagger call in this [URL](https://platformdev.cloud.coveo.com/docs?urls.primaryName=AuthorizationServer#/Organization%20Access/rest_organizations_paramId_access_groups_get) under the `Organization Access Resource`

![](./images/swagger-groups-call.png)

If you have trouble finding your call, feel free to ask to ask the team at [#support-platform](https://coveo.slack.com/archives/CSZPAAV1T) on Slack for guidance.

Once you have found the swagger call, you are given the relevant information you need to put this resource in Platform client.

# 2. Make changes required in Platform client

The repo can be found [here](https://github.com/coveo/platform-client) and you will need to clone and build it locally according to their README instructions.

Generally, you might already have the endpoint you need and can simply add an additional resource.

An example of adding an additional endpoint can be seen in [this PR](https://github.com/coveo/platform-client/pull/434/files) where we are adding two methods. `Platform.organization.updateAdditionalInformation` and `Platform.organization.getAdditionalInformation`

However, in this guide, the endpoint URL is not found for `getGroups` and `getApiKeys`. This commit shows you how the resource was added in [this PR.](https://github.com/coveo/platform-client/pull/524/files)

For the return type of the object data, you can refer to [this PR](https://github.com/coveo/platform-client/pull/525/files) where the enums were created and the return data was typed.

# 3. Test your Platform client changes locally with Admin-UI

Before creating the PR, you can actually test changes in Platform client locally by following [this guide.](https://docs.admin-ui.coveo.com/getting-started/appendix-running-local-changes#local-changes-from-platform-client)

From here, you can go to any functional component and use the `useAsync` Hook!

```tsx
export const YourFunctionalComponent = () => {
    const {value: groupData, loading: isLoading} = useAsync(() =>
        Platform.organizationAccess.getGroups({
            accessLevel: [AccessLevel.CUSTOM, AccessLevel.VIEW_ALL, AccessLevel.NONE, AccessLevel.EDIT_ALL],
            privilegeOwner: 'PLATFORM',
            privilegeTargetDomain: 'SOURCE',
        }),
    );

    console.log(value);

    return <div>Functional Component</div>;
};
```

If you see data being returned in your console log, you should be good to go!

# 4. Merging Platform client changes

Once you have merged your changes in Platform client, you will not be able to use the call just yet.

You can either **wait for it to automatically bump the package in Admin-UI** once it is deployed, or you can **create a new pull request in Admin-UI and manually bump the Platform client** using the command found in our [Appendix 4 - Updating dependencies](/getting-started/appendix-updating-dependencies) page.

# Adding experimental or internal resources

You can extend the Platform client class from the Admin-UI repo in the `@core/api` packages. This is useful when you do not want your resource to be available to the public. Please see [extending the client](https://github.com/coveo/platform-client#extending-the-client) for more information.
